import { User } from '../interfaces';

export const identifyAppcuesUser = (user: User, cohorts?: string[]) => {
  try {
    window.Appcues.identify(user.email, {
      role: user.role,
      firstName: user.name,
      lastName: user.surname,
      userCohorts: cohorts?.join(';') ?? '',
      accountName: user.clientName,
    });
  } catch (error) {
    console.log('appcues:', error);
  }
};

export const resetAppcues = () => {
  try {
    window.Appcues.reset();
  } catch (error) {
    console.error('appcues:', error);
  }
};

export const turOnAppcues = (user: User, cohorts?: string[]) => {
  try {
    window.Appcues.identify(user.email, {
      role: user.role || 'user',
      firstName: user.name,
      lastName: user.surname,
      userCohorts: JSON.stringify(cohorts),
      accountName: user.clientName,
    });
  } catch (error) {
    console.error('appcues:', error);
  }
};

/**
 * Method that deletes all the users history from Appcues
 * @param userEmails
 */
export const deleteUserHistoryAppcues = async (userEmails: string[], appcuesApiKey: string): Promise<void> => {
  const requestHeaders = new Headers();
  const encodedApiKey = Buffer.from(appcuesApiKey + ':').toString('base64');

  requestHeaders.append('Content-type', 'application/json');
  requestHeaders.append('Authorization', `Basic ${encodedApiKey}`);

  const requestBody = JSON.stringify({
    users: userEmails,
  });

  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  fetch('https://api.appcues.com/v1/gdpr/delete', requestOptions).catch((error) => console.log('error:', error));
};
