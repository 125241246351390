import { Course, Pathway } from '@/interfaces/explore';
import { UserCourseModule, UserPathwayModule } from '@/interfaces/user';
import { UserPathwayAssignment } from '@/interfaces/user/user-pathway';
import { KanbanBoardUserLayout } from '@/interfaces';

import { baseApiSlice } from './base-api-slice';

export const kanbanBoardApiSlice = baseApiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'UserCourses',
      'UserCourseModules',
      'UserPathways',
      'UserPathwayModules',
      'UserAssignments',
      'UserPathwaysAssessments',
      'UserCohorts',
      'BoardLayout',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserCourses: builder.query<Course[], void>({
        query: () => 'kanban-board/get-user-courses',
        transformResponse: (response: { data: Course[] }) => response.data,
        providesTags: ['UserCourses'],
      }),
      getUserCourseModules: builder.query<UserCourseModule[], void>({
        query: () => 'kanban-board/get-course-modules',
        transformResponse: (response: { data: UserCourseModule[] }) => response.data,
        providesTags: ['UserCourseModules'],
      }),
      getUserPathways: builder.query<Pathway[], void>({
        query: () => 'kanban-board/get-user-pathways',
        transformResponse: (response: { data: Pathway[] }) => response.data,
        providesTags: ['UserPathways'],
      }),
      getUserPathwayModules: builder.query<UserPathwayModule[], void>({
        query: () => 'kanban-board/get-pathway-modules',
        transformResponse: (response: { data: UserPathwayModule[] }) => response.data,
        providesTags: ['UserPathwayModules'],
      }),
      getUserAssignments: builder.query<UserPathwayAssignment[], void>({
        query: () => 'kanban-board/get-assignments',
        transformResponse: (response: { data: UserPathwayAssignment[] }) => response.data,
        providesTags: ['UserAssignments'],
      }),
      getUserCohorts: builder.query<string[], void>({
        query: () => 'kanban-board/get-cohorts-as-member',
        transformResponse: (response: { data: string[] }) => response.data,
        providesTags: ['UserCohorts'],
      }),
      getBoardLayout: builder.query<KanbanBoardUserLayout[], void>({
        query: () => 'kanban-board/get-board-layout',
        transformResponse: (response: { data: KanbanBoardUserLayout[] }) => response.data,
        providesTags: ['BoardLayout'],
      }),
    }),
  });

export const {
  useGetUserCoursesQuery,
  useGetUserCourseModulesQuery,
  useGetUserPathwaysQuery,
  useGetUserPathwayModulesQuery,
  useGetUserAssignmentsQuery,
  useGetUserCohortsQuery,
  useGetBoardLayoutQuery,
} = kanbanBoardApiSlice;
