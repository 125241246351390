import { Cohort } from '../../interfaces';
import { ChartsData } from '../../interfaces/analytics/charts-data';
import { UserAnalyticsDataType, UsersAnalyticsType } from '../../interfaces/analytics/users-table-data';
import { baseApiSlice } from './base-api-slice';

const analyticsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnalyticsChartDataByClientId: builder.query<ChartsData, { accountId: string; cohortId: string }>({
      query: ({ accountId, cohortId }) =>
        `analytics/get-charts-data?${new URLSearchParams({ accountId })}&${new URLSearchParams({ cohortId })}`,
      transformResponse: (response: { data: ChartsData }) => response.data,
    }),
    getUsersAnalytics: builder.query<{ numberOfMatchingUsers: number; users: UserAnalyticsDataType[] }, UsersAnalyticsType>({
      query: (userData: UsersAnalyticsType) => {
        let queryString = `analytics/get-users-analytics?page=${userData.page}&limit=${userData.limit}&search=${userData.search}&clientId=${userData.clientId}&type=${userData.type}`;
        if (userData.typeId) {
          queryString += `&typeId=${userData.typeId}`;
        }
        if (userData.filters) {
          queryString += `&filters=${JSON.stringify(userData.filters)}`;
        }
        if (userData.cohortId) {
          queryString += `&cohortId=${userData.cohortId}`;
        }
        return queryString;
      },
      transformResponse: (response: { data: { numberOfMatchingUsers: number; users: UserAnalyticsDataType[] } }) =>
        response.data,
    }),
    getCohortsByAccount: builder.query<Cohort[], string>({
      query: (clientId: string) => `cohort/get-cohorts-by-account?${new URLSearchParams({ clientId })}`,
      transformResponse: (response: { data: Cohort[] }) => response.data,
    }),
  }),
});

export const { useGetAnalyticsChartDataByClientIdQuery, useGetUsersAnalyticsQuery, useGetCohortsByAccountQuery } =
  analyticsApiSlice;
